<template>
    <button
        :class="['z-tag__button', `z-tag--${theme}`]"
        v-if="button">
        <slot />
    </button>
    <span
        :class="['z-tag', `z-tag--${theme}`]"
        v-else>
        <slot />
    </span>
</template>

<script>
export default {
    name: 'z-tag',
    props: {
        button: {
            type: Boolean
        },
        theme: {
            type: String,
            validator: (props) => [ 'primary', 'secondary', 'white', 'blue' ].includes(props),
            default: 'primary'
        }
    }
}
</script>

<style lang="scss">
.z-tag {
    padding: 4px $token-spacers-2-xs;
    font-weight: 400;
    font-size: var(--fontSizeS);
    color: $token-colors-black-50;
    line-height: 1.3;
    border: none;
    border-radius: 20px;
    display: inline-block;

    &--primary {
        background-color: #EFF6FB;
    }

    &--secondary {
        padding: $token-spacers-3-xs $token-spacers-2-xs;
        background-color: $token-colors-alert-bg;
        color: #576B90;
    }

    &--white {
        background-color: $token-colors-white;
        color: $token-colors-black-70;
    }

    &--blue {
        background-color: #80bbe3;
        color: $token-colors-white;
    }

    &--white,
    &--blue {
        font-weight: 500;
        line-height: 1.2;
        padding: $token-spacers-3-xs $token-spacers-xs;

        @include breakpoint(v-tablet) {
            padding: 4px $token-spacers-2-xs;
            font-size: var(--fontSizeXs);
        }
    }

    &__button {

        &.z-tag--primary,
        &.z-tag--secondary {
            color: $token-colors-black-80;
            font-weight: 400;
            font-size: var(--fontSizeXs);
            line-height: 1.2;
            padding: $token-spacers-3-xs $token-spacers-2-xs;
            background-color: $token-colors-warning-bg;
            border: none;
            border-radius: 20px;
            transition: background-color .15s ease-in-out;

            &:hover {
                background-color: $token-colors-warning-bg;
            }

            &:active {
                background-color: $token-colors-bg-11;
            }

            &:disabled {
                background-color: $token-colors-black-10;
            }
        }
    }
}
</style>
