<template>
    <component :is="tag" :href="tag === 'a' ? data.detailPageUrl : false" class="event-card">
        <div class="event-card__image" alt="Изображение" :style="`background-image: url('${data.detailImage.link}')`" v-if="data.detailImage.link"></div>
        <div class="event-card__image" alt="Изображение" style="background-image: url('/images/events/imageholder.svg')" v-else></div>
        <div class="event-card__info">
            <div class="event-card__header">
                <div class="event-card__date-wrapper">
                    <div class="event-card__date" v-if="data.dateFrom.timestamp">
                        <date-block size="xl" :date="data.dateFrom.timestamp" :date-end="data.dateTo.timestamp" />
                    </div>
                    <div class="event-card__time" v-if="data.time && data.time.value !== ''">
                        <z-icon name="clock" width="16" height="16" />
                        <div class="event-card__time-wrapper">
                            <span class="event-card__time-clock" v-html="data.time.value"></span>
                            <span class="event-card__time-utc" v-if="data.isNorilskTime.value === 'Y'">(Норильск – местное время)</span>
                            <span class="event-card__time-utc" v-else>(Москва - московское время)</span>
                        </div>
                    </div>
                </div>
                <div class="event-card__speaker" v-if="data.speaker && data.speaker.name !== ''">
                    <div class="event-card__speaker-photo" :style="`background-image: url('${data.speaker.previewPicture.link}')`"></div>
                    <div class="event-card__speaker-name" v-html="data.speaker.name"></div>
                </div>
            </div>
            <div class="event-card-tags" v-if="data.type && data.type.length">
                <z-tag theme="secondary" v-for="(item, index) in data.type" :key="index" v-html="data.type[index].value"></z-tag>
                <z-tag theme="secondary" v-html="data.formatEvent.value"></z-tag>
            </div>
            <div class="event-card__title" v-html="data.name"></div>
            <div class="event-card__status">
                <span v-if="!data.past">
                    <span v-if="data.formatRegistration.xmlId === 'site' ||
                    data.formatRegistration.xmlId === 'link'">Открыта регистрация</span>
                    <span v-else>Присоединиться</span>
                </span>
            </div>
            <div class="event-card__buttons" v-if="$slots['buttons']">
                <slot name="buttons" />
            </div>
        </div>
    </component>
</template>

<script>

export default {
    name: 'event-card',
    props: {
        data: Object,
        tag: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
.event-card {
    display: flex;
    background: $token-colors-white;
    border-radius: 20px;
    text-decoration: none;
    color: inherit;
    overflow: hidden;

    &:hover {
        color: inherit;
    }

    @include breakpoint (v-tablet) {
        flex-direction: column;
    }

    &__image {
        border-radius: 20px;
        width: 41%;
        padding-top: 28%;
        background-size: cover;
        background-position: 50% 50%;
        min-width: 430px;

        @include breakpoint (v-tablet) {
            padding-top: 200px;
            width: 100%;
            padding-top: 44%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include breakpoint (mobile) {
            min-width: auto;
            padding-top: 71%;
        }
    }

    &__info {
        padding: $token-spacers-s $token-spacers-m;
        position: relative;
        max-width: 59%;
        width: 100%;

        @include breakpoint (v-tablet) {
            max-width: 100%;
            padding-top: calc(#{$token-spacers-s} - 20px);

            &:before {
                content: '';
                background: $token-colors-white;
                position: absolute;
                top: -20px;
                left: 0;
                width: 100%;
                height: 20px;
                display: block;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
        }

        @include breakpoint (mobile) {
            padding-left: $token-spacers-xs;
            padding-right: $token-spacers-xs;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;

        @include breakpoint (mobile) {
            flex-direction: column;
        }
    }

    &__date-wrapper {
        display: flex;

        @include breakpoint (tablet) {
            flex-direction: column;
        }
    }

    &__buttons {
        margin-top: $token-spacers-xs;
        display: flex;
        align-items: center;

        .z-icon {
            transition: opacity $transition;

            &:hover {
                opacity: .8;
                cursor: pointer;
            }
        }
    }

    &__speaker {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: $token-spacers-xs;

        @include breakpoint (mobile) {
            flex-direction: row;
            align-items: center;
            margin-left: 0;
            margin-top: $token-spacers-xs;
        }

        &-photo {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            flex-shrink: 0;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &-name {
            margin-top: 4px;
            text-align: end;
            font-weight: 600;
            // font-size: 18px;
            font-size: var(--fontSizeL);
            line-height: 1.4;

            @include breakpoint (mobile) {
                text-align: start;
                margin-top: 0;
                margin-left: $token-spacers-3-xs;
            }
        }
    }

    &__time {
        // font-size: 18px;
        font-size: var(--fontSizeL);
        color: $token-colors-black-80;
        margin-left: $token-spacers-s;
        display: flex;
        margin-top: 0.5em;

        @include breakpoint (tablet) {
            margin-left: 0;
            margin-top: $token-spacers-xs;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            margin-left: $token-spacers-3-xs;
        }

        &-clock {
            font-weight: 600;
            line-height: 1;
            margin-bottom: 0.3em;
        }

        &-utc {
            color: $token-colors-black-60;
            line-height: 1.2;
        }
    }

    &-tags {
        display: flex;
        flex-wrap: wrap;
        margin: $token-spacers-2-xs -4px;

        > * {
            margin: $token-spacers-3-xs 4px 0;
        }

        @include breakpoint (mobile) {
            margin: $token-spacers-2-xs 0;
        }
    }

    &__title {
        font-weight: 700;
        // font-size: 20px;
        font-size: var(--fontSizeXl);
        line-height: 1.3;
    }

    &__status {
        margin-top: $token-spacers-xs;
        font-weight: 600;
        // font-size: 18px;
        font-size: var(--fontSizeL);
        color: $token-colors-black-70;

        @include breakpoint (mobile) {
            margin-top: $token-spacers-2-xs;
            // font-size: 16px;
            // font-size: var(--fontSizeM);
        }
    }
}
</style>
