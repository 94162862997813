<template>
    <div class="filter">
        <div class="filter__wrapper">
            <z-select-multi
                class="filter__item"
                :data="data.type"
                v-model="params.type"
                placeholder="Тип мероприятия"
                @change="changeFilter"
                is-filter
            ></z-select-multi>
            <z-select
                class="filter__item"
                :data="data.format"
                v-model="params.format"
                placeholder="Формат"
                @change="changeFilter"
                is-filter
            ></z-select>
            <div class="filter-datapicker filter__item">
                <z-date-picker
                    class="filter__item"
                    placeholder="с"
                    is-filter
                    v-model="params.from"
                    @change="changeFilter"
                />
                <span class="hide-me--mobile">—</span>
                <z-date-picker
                    class="filter__item"
                    placeholder="по"
                    is-filter
                    v-model="params.to"
                    @change="changeFilter"
                />
            </div>
            <z-link
                class="filter__clear"
                href="javacript:void(0)"
                icon="close-round"
                icon-position="right"
                @click="onClear"
                v-if="showResetButton"
                :underlined="false"
            >Сбросить фильтры</z-link>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'

export default {
    name: 'events-filter',
    props: {
        data: Object,
        initParams: Object
    },
    data () {
        return {
            params: {
                from: '',
                to: '',
                type: [],
                format: ''
            }
        }
    },
    computed: {
        showResetButton () {
            return Boolean(
                this.params.from !== '' ||
                this.params.to !== '' ||
                this.params.type !== [] ||
                this.params.format !== ''
            )
        }
    },
    methods: {
        onClear () {
            this.clearParams()
            this.changeFilter()
        },
        clearParams () {
            this.params = {
                from: '',
                to: '',
                type: [],
                format: '',
                page: 1
            }
        },
        changeFilter () {
            this.$emit('change-filter', this.params)

            const params = {}
            this.params.type && (params.type = this.params.type)
            this.params.format && (params.format = this.params.format)
            this.params.from && (params.from = this.params.from)
            this.params.to && (params.to = this.params.to)

            queryString(params)
        }
    },
    mounted () {
        this.params = { ...this.params, ...this.initParams }
    }
}
</script>

<style lang="scss">
.filter {
    &-datapicker {
        display: flex;
        align-items: center;

        @include breakpoint (mobile) {
            flex-direction: column;

            > * + * {
                padding-left: 0;
                padding-top: 12px;
            }

            .mx-datepicker {
                min-width: 160px;
            }
        }

        span {
            padding: 0 4px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint (v-tablet) {
            flex-direction: column;
        }

        > * + * {
            padding-left: 4px;

            @include breakpoint (v-tablet) {
                padding-left: 0;
                padding-top: 12px;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        .filter__clear {
            position: absolute;
            right: 0;
            bottom: -45px;
        }
    }

    &__item {
        width: 100%;

        &.z-date-picker {
            max-width: 200px;

            @include breakpoint (v-tablet) {
                max-width: 100%;
            }
        }
    }
}
</style>
