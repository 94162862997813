<template>
    <div class="events-detail">
        <div class="events-detail__card-wrapper">
            <event-card :data="data.item" tag="div">
                <template v-slot:buttons>
                    <template v-if="showRegistrationButton">
                        <z-button
                            size="l"
                            v-if="!data.item.past"
                            :tag="registrationButton.tag"
                            :href="registrationButton.href"
                            :target="registrationButton.target"
                        >
                            Зарегистрироваться
                            <template v-slot:right>
                                <z-icon name="buttons/arrow_right" width="20" height="20" />
                            </template>
                        </z-button>
                        <z-button size="l" disabled v-else>Мероприятие закончилось</z-button>
                    </template>
                    <div class="event-card__buttons-tools">
                        <z-share :services="shareServices">
                            <z-icon name="round/share" width="55" height="55" />
                        </z-share>
                        <remind :data="data.item.remind" v-if="data.item.past === false">
                            <z-icon name="round/remind" width="55" height="55" />
                        </remind>
                    </div>
                </template>
            </event-card>
        </div>
        <section class="u-top-margin--2xl">
            <div class="row">
                <div class="col-default-9 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                    <template v-if="data.item.additionalInfo">
                        <vue-raw :raw="data.item.additionalInfo.value" class="u-bottom-margin--l"></vue-raw>
                    </template>
                    <template v-if="data.item.speaker && data.item.speaker.name">
                        <z-caption tag="h2" size="4xl" weight="700" class="u-bottom-margin--m-important">Спикеры</z-caption>
                        <z-blockquote
                            :author="{
                                photo: data.item.speaker.previewPicture.link,
                                name: data.item.speaker.name,
                                info: data.item.speaker.position.value
                            }"
                        >
                        </z-blockquote>
                    </template>
                    <template v-if="data.item.address && data.item.formatEvent.xmlId === 'ofline'">
                        <z-card theme="white" size="l" class="u-top-margin--l u-bottom-margin--2xl">
                            <template v-slot:content>
                                <z-caption tag="h3" size="2xl" weigth="600" class="u-bottom-margin--2xs-important">Адрес проведения мероприятия</z-caption>
                                <p class="text-size-m" v-html="data.item.address.value"></p>
                            </template>
                        </z-card>
                    </template>
                </div>
            </div>
        </section>
        <template v-if="data.item.lectureFor && data.item.lectureFor.length">
            <section class="u-block-fluid">
                <div class="row">
                    <div class="col-default-9 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption tag="h2" size="4xl" weight="700">Для кого эта лекция</z-caption>
                        <z-list bordered icon="check" size="l" class="u-top-margin--l">
                            <z-list-item v-for="(item, index) in data.item.lectureFor" :key="index">
                                <span v-html="item.value"></span>
                            </z-list-item>
                        </z-list>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="data.item.aboutLecture.value">
            <section class="u-block-fluid">
                <div class="row">
                    <div class="col-default-9 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption tag="h2" size="4xl" weight="700" class="u-align-center">О&nbsp;чем эта лекция</z-caption>
                        <vue-raw :raw="data.item.aboutLecture.value"></vue-raw>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="data.item.detailText && !data.item.past">
            <section class="u-block-fluid">
                <div class="row">
                    <div class="col-default-9 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption tag="h2" size="4xl" weight="700">Что вы узнаете и&nbsp;сможете применить на&nbsp;практике после лекции</z-caption>
                        <vue-raw :raw="data.item.detailText" class="u-top-margin--2xl"></vue-raw>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="data.item.past && data.item.videogallery.length">
            <section class="u-block-fluid">
                <div class="row">
                    <div class="col-default-12 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption tag="h2" size="4xl" weight="700" class="u-align-center">Видео с мероприятия</z-caption>
                        <slider :buttons="true" controls-placement="center" is-video>
                            <slide v-for="(item, index) in data.item.videogallery" :key="index">
                                <z-figure
                                    :video='true'
                                    :caption='item.name'
                                >
                                    <z-video
                                        :autoplay='false'
                                        :src='item.link'>
                                    </z-video>
                                </z-figure>
                            </slide>
                        </slider>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="data.item.past && data.item.mediagallery.length">
            <section class="u-block-fluid">
                <div class="row">
                    <div class="col-default-12 col-v-tablet-12 col-v-tablet-offset-0">
                        <z-caption tag="h2" size="4xl" weight="700" class="u-align-center">Фото с&nbsp;мероприятия</z-caption>
                        <slider :buttons="true" controls-placement="center">
                            <slide v-for="(item, indexM) in data.item.mediagallery" :key="indexM">
                                <z-figure
                                    :caption='item.name'
                                    :image="item.link"
                                />
                            </slide>
                        </slider>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="data.item.formatRegistration.xmlId === 'site'">
            <div class="u-block-fluid" ref="form" id="event-registration-form">
                <event-registration-form
                :id="data.item.id"
                :show-event-address="Boolean(data.item.showEventAddress.value)"
                :event-address="data.item.eventAddress"
                />
            </div>
        </template>
        <section v-if="data.nav.prev || data.nav.next" class="u-block-fluid u-block-fluid--page-changer">
            <page-changer
                :data="{
                    next: {
                        name: '',
                        url: data.nav.next ?  data.nav.next.url : ''
                    },
                    prev: {
                        name: '',
                        url: data.nav.prev ? data.nav.prev.url : ''
                    },
                    ['back-link']: data.nav['back-link']
                }"
            />
        </section>
        <template v-if="data.item.linkedNews && data.item.linkedNews.length">
            <section class="u-block-fluid u-block-fluid--news">
                <z-caption tag="h2" class="u-bottom-margin--l-important">Новости по&nbsp;теме</z-caption>
                <news-list :data="data.item.linkedNews" />
            </section>
        </template>
    </div>
</template>

<script>
import EventCard from './components/EventCard.vue'

export default {
    name: 'events-detail',
    components: {
        EventCard
    },
    data () {
        return {
            shareServices: ['vkontakte', 'odnoklassniki', 'telegram', 'viber', 'whatsapp', 'moimir', 'lj'],
            data: this.$root.app.components['events-detail']
        }
    },
    methods: {
        scrollToForm (obj) {
            if (obj) return false
            if (this.$refs.form) {
                this.$refs.form.scrollIntoView({block: 'start', behavior: 'smooth'})
            }
        },

        updateRegistrationConfig (tag, href, target) {
            return {
                tag: tag,
                href: href,
                target: target
            }
        }
    },
    computed: {
        registrationButton () {
            switch (this.data.item.formatRegistration.xmlId) {
            case ('site'): {
                return this.updateRegistrationConfig('a', '#event-registration-form', '_self')
            }
            case ('link'): {
                return this.updateRegistrationConfig('a', this.data.item.translationLink.value, '_blank')
            }
            default: {
                return this.updateRegistrationConfig('button', '', '_self')
            }
            }
        },

        showRegistrationButton () {
            return this.data.item.formatRegistration.xmlId === 'site' || this.data.item.formatRegistration.xmlId === 'link'
        }
    }
}
</script>

<style lang="scss">
html {
    scroll-behavior: smooth;
}
.events-detail {
    @include margin-level(left, 2XL, true);
    @include margin-level(right, 2XL, true);

    @include breakpoint(v-tablet) {
        margin-right: 0;
        margin-left: 0;
    }

    @include breakpoint(mobile) {
        .remind .z-icon,
        .share-tooltip .z-icon {
            width: 45px !important;
            height: 45px !important;
        }

        .share-tooltip .z-share__title .z-icon {
            width: 24px !important;
        }
    }

    &__card-wrapper {
        @include padding-level(left, 2XL);
        @include padding-level(right, 2XL);

        @include breakpoint(v-tablet) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .u-block-fluid {
        padding-bottom: calc(#{$token-spacers-2-xl} + #{$borderRadius}); //необходимый отступ с учетом компенсации радиуса соседнего (нижнего) блока
        padding-top: $token-spacers-2-xl;

        + .u-block-fluid {
            margin-top: -$borderRadius;
        }

        &:last-child {
            padding-bottom: $token-spacers-l;
        }

        &:nth-child(odd) {
            background: $token-colors-white;
        }

        &:nth-child(even) {
            background: $token-colors-bg-5;
        }

        &--page-changer {
            background: linear-gradient(103.74deg, #CDE4F4 -0.9%, #EFF6FB 68.78%) !important;

            .page-changer {
                margin-top: -$token-spacers-m;
                margin-bottom: -$token-spacers-m;
            }
        }

        &--news {
            background-color: $token-colors-bg-5 !important;
        }
    }

    .event-card {
        justify-content: space-between;
    }

    .event-card__image {
        width: 57%;
        padding-top: 39%;
        order: 2;

        @include breakpoint (tablet) {
            width: 50%;
        }

        @include breakpoint (v-tablet) {
            width: 100%;
        }

        @include breakpoint (mobile) {
            min-width: auto;
            padding-top: 71%;
        }
    }

    .event-card__info {
        width: 43%;

        @include breakpoint (v-tablet) {
            order: 2;
            width: 100%;
        }
    }

    .event-card__title {
        font-size: var(--fontSize4xl);
        line-height: 1.2;
    }

    .event-card__buttons {
        &:first-child {
            @include breakpoint (mobile) {
                width: 100%;
            }
        }

        &-tools {
            margin-left: $token-spacers-2-xs;
            display: flex;

            > * + * {
                margin-left: $token-spacers-2-xs;
            }
        }

        @media screen and (max-width: 450px) {
            display: flex;
            flex-direction: column;

            &-tools {
                margin-top: 16px;
                margin-left: 0;
            }
        }
    }

    .event-card__speaker,
    .event-card__time {
        display: none;
    }
}
</style>
