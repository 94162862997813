<template>
    <section class="digest-form">
        <div class="container">
            <z-caption tag="h2" size="4xl" class="digest-form__title u-semi-bold u-top-margin--2xl">Мы ждем ваши <br>
                <span class="primary-text">предложения по развитию</span>
            </z-caption>
            <div class="digest-form__block">
                <div>
                    <z-caption tag="h2" size="4xl" class="digest-form__title--mobile u-semi-bold u-bottom-margin--m">Мы ждем ваши<br>
                        <span class="primary-text">предложения по развитию</span>
                    </z-caption>
                    <h3 class="digest-form__name u-semi-bold">
                        Заполните форму и&nbsp;поделитесь <span>своим&nbsp;мнением</span>
                    </h3>
                    <ul>
                        <li class="digest-form__item">
                            <span>Нравится&#160;ли вам такой формат дайджеста?</span>
                        </li>
                        <li class="digest-form__item">
                            <span>Какие темы нам стоит раскрывать подробнее?</span>
                        </li>
                        <li class="digest-form__item">
                        <span>Какие у&#160;вас есть идеи по&#160;развитию Дайджеста?</span>
                        </li>
                    </ul>
                </div>
                <div class="digest-form__block-form">
                    <form class="digest-form__form" id="form" @submit.prevent="submitForm" v-if="!successFormSend">
                        <div class="digest-form__wrapper">
                            <z-input
                                placeholder="Ваше имя"
                                name="fio"
                                v-model="formData.fio"
                                ref="fio"
                                type="text"
                            ></z-input>
                            <z-input
                                placeholder="E-mail"
                                name="email"
                                v-model="formData.email"
                                ref="email"
                                type="email"
                            ></z-input>
                        </div>
                        <z-textarea
                            placeholder="Введите текст сообщения"
                            required
                            name="text"
                            ref="text"
                            v-model="formData.text"
                        ></z-textarea>
                        <z-checkbox
                            class="u-top-margin--s"
                            v-model="isChecked"
                            name="policy"
                            ref="policy"
                            required
                            size="s"
                            :data="[
                    {
                        text: `В&#160;соответствии с&#160;Федеральным законом &#171;О&#160;персональных данных&#187; (&#8470;&#160;152-ФЗ) свободно, своей волей и&#160;в&#160;своем интересе я&#160;даю свое согласие ЧОУ ДПО Корпоративный университет &#171;Норильский никель&#187; на&#160;обработку моих персональных данных, указанных при заполнении формы подписки на&#160;новости.`,
                        value: 'agree'
                    }
                ]"
                        >
                        </z-checkbox>
                        <div class="digest-form__footer">
                            <z-button>Отправить</z-button>
                            <z-link
                                href="javascript: void(0)"
                                icon-position="right"
                                icon="close-round"
                                size="s"
                                @click="clearForm"
                                v-if="showResetButton"
                                :underlined="false"
                            >Очистить форму
                            </z-link>
                        </div>
                    </form>
                    <notification v-else>
                        <template v-slot:image>
                            <z-image src="/images/not-found/form-success.svg" width="316" height="266"></z-image>
                        </template>
                        <template v-slot:description>
                            <z-caption size="m">Спасибо за ваше мнение!</z-caption>
                            <p class="text-size-xl u-regular">Ваше сообщение отправлено сотруднику университета.</p>
                        </template>
                    </notification>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendDigestForm } from '@/api/digest.js'

export default {
    name: 'digest-form',
    data () {
        return {
            formData: {
                fio: '',
                email: '',
                text: ''
            },
            isChecked: [],
            successFormSend: false
        }
    },
    computed: {
        showResetButton () {
            return Boolean(
                this.formData.fio ||
                this.formData.email ||
                this.formData.text ||
                this.isChecked.length
            )
        }
    },
    methods: {
        clearForm () {
            this.formData.fio = ''
            this.formData.email = ''
            this.formData.text = ''
            this.isChecked = []
        },
        validateRequiredInputs () {
            const requiredFields = ['fio', 'email', 'text']

            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        async submitForm () {
            if (!this.isChecked.length) {
                showNotyfications('Необходимо согласие на обработку персональных данных', { type: 'error' })
                return
            }
            const isValidRequiredInputs = this.validateRequiredInputs()
            if (isValidRequiredInputs) {
                try {
                    const data = new FormData()
                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }

                    this.isChecked.forEach((value, index) => {
                        data.append(`isChecked[${index}]`, value)
                    })

                    const formResponse = await sendDigestForm(data)
                    if (formResponse.status === 'success') {
                        showNotyfications('Отправка успешна', { type: 'success' })
                        this.clearForm()
                    } else {
                        showNotyfications('Ошибка отправки', { type: 'error' })
                    }
                } catch (error) {
                    showNotyfications('Ошибка отправки', { type: 'error' })
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
            console.log('submit')
        }
    }
}
</script>

<style lang="scss">
.digest-form {
    @include padding-level(bottom, 2XL);

    @include breakpoint (mobile) {
        padding-bottom: 0;
    }

    &__title {
        max-width: 934px;
        font-size: 66px !important;
        margin-top: 0;
        @include margin-level(bottom, 2XL);

        @include breakpoint(tablet) {
            @include typo-level(4XL, true);
            display: none;
        }

        span {
            color: $token-colors-primary;
        }

        &--mobile {
            display: none;
            margin-bottom: 24px !important;

            span {
                color: $token-colors-primary;
            }

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }

    &__block {
        padding: $token-spacers-xl;
        background: linear-gradient(0deg, #DFEDFD 0%, #DFEDFD 100%), #FFF;
        border-radius: 32px;
        display: flex;
        gap: 71px;

        @include breakpoint(tablet) {
            gap: 30px;
        }

        @include breakpoint(tablet) {
            flex-direction: column;
            @include padding-level(left, L);
            @include padding-level(right, L);
            @include padding-level(top, XL);
            @include padding-level(bottom, L);
        }

        @include breakpoint (mobile) {
            background: radial-gradient(112.97% 134.46% at 111.11% 3.75%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(201deg, #CDE4F4 9.38%, #EFF6FB 65.73%);
            margin-left: -16px;
            margin-right: -16px;
            padding-bottom: 72px;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 32px;
        }
    }

    &__block-form {
        width: 52%;
        flex-shrink: 0;

        @include breakpoint (tablet) {
            width: 100%;
        }
    }

    &__name {
        @include margin-level(bottom, L);
        @include typo-level(4XL);

        @include breakpoint (tablet) {
            @include typo-level(2XL);
        }

        @include breakpoint (mobile) {
            @include typo-level(XL);
        }

        span {
            color: $token-colors-primary;
        }
    }

    &__item {
        @include padding-level(bottom, M);
        @include typo-level(2XL);
        font-weight: 600;

        @include breakpoint (tablet) {
            font-size: 18px;
        }

        @include breakpoint (mobile) {
            @include typo-level(XL);
            font-weight: 400;
        }

        &:last-child {
            padding-bottom: 0;
        }

        span {
            position: relative;
            display: block;
            padding-left: 32px;

            @include breakpoint (mobile) {
                padding-left: 24px;
            }

            &:before {
                content: '';
                background-image: url("/images/digest/mark.svg");
                background-size: contain;
                background-position: center;
                display: block;
                position: absolute;
                top: 0.7em;
                transform: translateY(-50%);
                left: 0;
                width: 14px;
                height: 14px;

                @include breakpoint(v-tablet) {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    &__form {
        @include padding-level(left, XL);
        @include padding-level(right, XL);
        @include padding-level(top, L);
        @include padding-level(bottom, L);
        background: $token-colors-white;
        border-radius: $borderRadius;
        max-width: 850px;
        min-width: 500px;

        @include breakpoint(tablet) {
            border-radius: 24px;
            padding: 48px 40px;
            min-width: 100%;
            max-width: 100px;
        }
        @include breakpoint(mobile) {
            border-radius: $borderRadiusMobile;
            padding: 20px;
            padding-top: 32px;
        }
    }

    &__wrapper {
        display: flex;
        gap: 16px;
        @include breakpoint(tablet) {
            flex-direction: column;
            gap: 32px;
        }

        > * {
            width: 50% !important;

            @include breakpoint(tablet) {
                width: 100% !important;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $token-spacers-l;

        @include breakpoint(mobile) {
            gap: 8px;

            .z-button {
                width: fit-content;
                font-size: 16px;
                padding: 16px 24px;
                flex-shrink: 0;
            }

            .z-link {
                font-size: 13px;

                &__text {
                    font-size: inherit;
                }
            }
        }
    }

    .z-textarea {
        margin-top: $token-spacers-s;

        textarea {
            border-radius: 10px;
        }
    }

    .z-checkbox__text {
        color: $token-colors-form-placeholder-color-disabled;
    }

    .z-input {
        input {
            border-radius: 10px;
        }

        p {
            margin-bottom: 4px;
            font-size: 13px;
        }
    }
}

</style>
