<template>
    <form class="event-form" @submit.prevent="submitForm">
        <z-caption tag="h2" size="3xl" weight="700" class="u-bottom-margin--l-important">Зарегистрироваться на&nbsp;мероприятие</z-caption>
        <z-input
            class="event-form__input"
            type="text"
            placeholder="Ваше имя"
            name="name"
            v-model="params.name"
            ref="name"
            required
        ></z-input>
        <div class="event-form__select-wrapper">
            <z-select
                v-if="showEventAddress && this.eventAddressOptions.length"
                class="event-form__input"
                placeholder="Место проведения"
                :data="eventAddressOptions"
                name="event-address"
                v-model="params.eventAddress"
                ref="eventAddress"
                required
            ></z-select>
        </div>
        <div class="event-form__wrapper">
            <div>
                <z-input
                    class="event-form__input"
                    type="email"
                    placeholder="Email"
                    name="email"
                    v-model="params.email"
                    ref="email"
                    required
                ></z-input>
            </div>
            <div>
                <z-input
                    class="event-form__input"
                    type="tel"
                    placeholder="Телефон"
                    name="tel"
                    v-model="params.tel"
                    ref="tel"
                    mask="+7(###)###-##-##"
                    required
                ></z-input>
            </div>
        </div>
        <z-checkbox
            class="u-top-margin--s"
            v-model="params.isChecked"
            name="policy"
            ref="policy"
            required
            size="s"
            :data="[{
                text: `Даю согласие на&nbsp;обработку <z-link href='/terms/'>персональных данных</z-link>.`,
                value: 'agree'
            }]"
        >
        </z-checkbox>
        <z-button class="u-top-margin--s" size="l">Отправить заявку</z-button>
    </form>
</template>

<script>
import { sendEventForm } from '@/api/event-form.js'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'event-registration-form',
    data () {
        return {
            params: {
                name: '',
                email: '',
                tel: '',
                eventAddress: '',
                isChecked: [],
                id: this.id
            }
        }
    },
    props: {
        id: String,
        showEventAddress: {
            type: Boolean,
            default: false
        },
        eventAddress: [Array, Object]
    },
    computed: {
        eventAddressOptions () {
            if (!Array.isArray(this.eventAddress)) return []
            return this.eventAddress.map(item => ({ id: item.value, text: item.value }))
        }
    },
    methods: {
        clearForm () {
            this.params = {
                name: '',
                email: '',
                tel: '',
                eventAddress: '',
                isChecked: [],
                id: this.id
            }
        },
        validateRequiredInputs () {
            const requiredFields = ['tel', 'policy', 'name', 'email']
            if (this.showEventAddress && this.eventAddressOptions.length) {
                requiredFields.push('eventAddress')
            }

            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        async submitForm () {
            const isValidRequiredInputs = this.validateRequiredInputs()
            if (isValidRequiredInputs) {
                try {
                    const data = new FormData()
                    for (const key in this.params) {
                        data.append(key, this.params[key])
                    }

                    const formResponse = await sendEventForm(data)
                    if (formResponse.status === 'success') {
                        showNotyfications('Отправка успешна', { type: 'success' })
                        this.clearForm()
                    } else {
                        showNotyfications('Ошибка отправки', { type: 'error' })
                    }
                } catch (error) {
                    showNotyfications('Ошибка отправки', { type: 'error' })
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
            console.log('submit')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
