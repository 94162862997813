<template>
    <component :is="tag" :href="getCardLink" :style="getCardStyle" :class="getCardClass">
        <div v-if="cardType === 'event'" class="card__container">
            <div
                class="card__tags"
                v-if="(data.type && data.type.length) || data.formatEvent.value"
            >
                <z-tag theme="white" v-html="data.formatEvent.value" />
                <z-tag
                    theme="blue"
                    v-for="(_item, idx) in data.type"
                    :key="idx"
                    v-html="data.type[idx].value"
                />
            </div>
            <div class="card__header">
                <div v-if="data.dateFrom.timestamp" class="card__header-date">
                    <date-block
                        size="xl"
                        :date="data.dateFrom.timestamp"
                        :date-end="data.dateTo.timestamp"
                    />
                </div>
                <div class="card__header-time" v-if="data.timeFrom && data.timeFrom.value !== ''">
                    <z-icon name="clock" width="16" height="22" />
                    <div class="card__header-time-wrapper">
                        <span class="card__header-time-from-to">
                            <span v-html="data.timeFrom.value"></span>
                            <span>–</span>
                            <span v-html="data.timeTo.value"></span>
                        </span>
                        <span class="card__header-time-utc" v-if="data.isNorilskTime.value === 'Y'">
                            (Норильск – местное время)
                        </span>
                        <span class="card__header-time-utc" v-else>(Московское время)</span>
                    </div>
                </div>
            </div>
            <z-caption class="card__title" tag="h1" size="3xl" weight="600" v-html="data.name" />
            <z-caption class="card__caption" tag="p" size="m" v-html="data.previewText" />
            <div class="card__speaker" v-if="data.speaker && data.speaker.name !== ''">
                <z-image class="card__speaker-photo" :src="data.speaker.previewPicture.link" />
                <div class="card__speaker-info">
                    <z-caption
                        class="card__speaker-position"
                        tag="p"
                        size="s"
                        weight="400"
                        v-html="data.speaker.position.value"
                    />
                    <z-caption
                        class="card__speaker-name"
                        tag="p"
                        size="m"
                        weight="600"
                        v-html="data.speaker.name"
                    />
                </div>
            </div>
        </div>

        <div v-else class="card__container">
            <z-caption tag="p" size="4xl" class="card__banner" weight="600">
                Следите за&nbsp;обновлениями в&nbsp;наших
                <z-caption
                    tag="a"
                    href="https://vk.com/university.nornik"
                    target="_blank"
                    size="4xl"
                >
                    соцсетях
                </z-caption>
                или посмотрите
                <z-caption tag="a" href="/press-center/events/#tabs-past" size="4xl">
                    прошедшие мероприятия
                </z-caption>
            </z-caption>
            <z-caption tag="p" size="m">
                В&nbsp;ближайшее время мероприятий не&nbsp;ожидается
            </z-caption>
        </div>
    </component>
</template>

<script>
export default {
    name: 'event-card',
    props: {
        cardType: {
            type: String,
            validator: (prop) => ['no-event', 'event'].includes(prop),
            default: 'no-event'
        },
        data: {
            type: Object
        },
        tag: {
            type: String,
            default: 'div'
        }
    },
    computed: {
        getCardClass () {
            return ['card', `card--${this.cardType}`]
        },

        getCardLink () {
            return this.tag === 'a' ? this.data.detailPageUrl : false
        },

        getCardStyle () {
            if (this.cardType === 'event') {
                return this.data?.previewPicture?.link
                    ? `background-image: url('${this.data?.previewPicture?.link}')`
                    : `background-image: url('/images/events/event-card-background.png')`
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
